export namespace PublicationActions {
  export class FetchAll {
    static readonly type = '[Publication] Fetch All';
  }

  export class Fetch {
    static readonly type = '[Publication] Fetch';
    constructor(public slug: string) {}
  }

  export class FetchFromTriber {
    static readonly type = '[Publication] Fetch Triber Publication';
    constructor(public triberId: string) {}
  }

  export class Delete {
    static readonly type = '[Publication] Delete';
    constructor(public uid: string) {}
  }
}
