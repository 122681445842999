import { Resume } from '@api';

export namespace ResumeActions {
  export class FetchAll {
    static readonly type = '[Resume] Fetch All';
  }
  export class FindOne {
    static readonly type = '[Resume] Fetch One with ID';
    constructor(public uid: Resume['uid']) {}
  }
}
