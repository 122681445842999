import { inject, Injectable } from '@angular/core';
import { Publication, PublicationAllRelations, PublicationsApi } from '@api';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { SearchActions } from '../searches/search.actions';
import { PublicationActions } from './publication.actions';

export interface PublicationRelations
  extends Pick<
    PublicationAllRelations,
    | 'triber'
    | 'category'
    | 'certifications'
    | 'specialities'
    | 'languages'
    | 'paymentModel'
    | 'school'
  > {}

export type PublicationItemState = Publication & Partial<PublicationRelations>;
export interface PublicationsStateModel {
  publications: Array<PublicationItemState>;
  loadedProfiles: string[];
  loading: boolean;
  loaded: boolean;
}

@State<PublicationsStateModel>({
  name: 'publications',
  defaults: {
    publications: [],
    loadedProfiles: [],
    loading: false,
    loaded: false,
  },
})
@Injectable()
export class PublicationsState implements NgxsOnInit {
  private publicationsAPI = inject(PublicationsApi);
  @Selector()
  static publications(state: PublicationsStateModel): Array<Publication | PublicationAllRelations> {
    return state.publications;
  }

  @Selector()
  static publicationBySlug(state: PublicationsStateModel) {
    return (slug: string) => {
      return state.publications.find(p => p.slug === slug);
    };
  }

  @Selector()
  static publicationsTribers(state: PublicationsStateModel) {
    return (triberId: string) => {
      return state.publications.filter(element => element.triberId === triberId);
    };
  }
  @Selector()
  static publicationsLoading(state: PublicationsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: PublicationsStateModel) {
    return state.loaded;
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    ctx.dispatch(new PublicationActions.FetchAll());
  }

  /**
   * Elimina una publicación del estado.
   * @param {StateContext<PublicationsStateModel>} ctx - Contexto del estado.
   * @param {PublicationActions.Delete} action - Acción de publicación para eliminar.
   */
  @Action(PublicationActions.Delete)
  removePublication(ctx: StateContext<PublicationsStateModel>, action: PublicationActions.Delete) {
    const state = ctx.getState();
    ctx.patchState({
      publications: state.publications.filter(p => p.uid !== action.uid),
    });
  }

  @Action(PublicationActions.Fetch)
  fetchPublication(ctx: StateContext<PublicationsStateModel>, { slug }: PublicationActions.Fetch) {
    const state = ctx.getState();
    //check if exists
    // todo: optimizar con arreglo de indices
    const publication = state.publications.find(p => p.slug === slug);
    if (publication) {
      return;
    }
    return this.publicationsAPI
      .findBySlug({ slug })
      .pipe(
        tap(publication => ctx.patchState({ publications: [...state.publications, publication] }))
      );
  }

  // fetchtriber
  @Action(PublicationActions.FetchFromTriber)
  fetchPublicationByUid(
    ctx: StateContext<PublicationsStateModel>,
    { triberId }: PublicationActions.FetchFromTriber
  ) {
    const state = ctx.getState();
    if (state.loadedProfiles.includes(triberId)) return;

    return this.publicationsAPI
      .findPublicationAll({
        limit: 20,
        triberUID: triberId,
      })
      .pipe(
        tap(publicationResponse => {
          const publicationsUidFromResponse = publicationResponse.items.map(item => item.uid);
          const publicationStateWithoutResponse = state.publications.filter(
            item => !publicationsUidFromResponse.includes(item.uid)
          );
          ctx.patchState({
            loadedProfiles: [...state.loadedProfiles, triberId],
            publications: [...publicationStateWithoutResponse, ...publicationResponse.items],
          });
        })
      );
  }

  /**
   * Obtiene todas las publicaciones y las almacena en el estado.
   * @param {StateContext<PublicationsStateModel>} ctx - Contexto del estado.
   * @returns {Observable<any>} - Observable que devuelve las publicaciones obtenidas.
   */
  @Action(PublicationActions.FetchAll)
  fetchAllPublications(ctx: StateContext<PublicationsStateModel>) {
    return this.publicationsAPI
      .findPublicationAll({
        limit: 50,
      })
      .pipe(
        tap(publications => {
          const publicationsItems = publications.items;
          ctx.patchState({
            publications: publicationsItems,
            loading: true,
            loaded: true,
          });
        })
      );
  }
}
